import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { helpers, storage } from "../../../services";
import { Link } from "react-router-dom";
import Actions from "../../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../hooks";

const ProductCard = ({ item, currentLangCode, t }) => {
  const dispatch = useDispatch();
  const { notification } = useNotification();
  const cartItems = useSelector((state) => state.auth.cartProducts);
  const [hasCart, setHasCart] = useState(false);

  // Check if the item is in the cart whenever cartItems change
  useEffect(() => {
    const isInCart = cartItems.some((c) => c.id === item.id);
    setHasCart(isInCart);
  }, [cartItems, item.id]);

  const addToCart = () => {
    const cartItemExists = cartItems.some((c) => c.id === item.id);

    const updatedCartItems = cartItemExists
      ? cartItems.filter((c) => c.id !== item.id) // Remove if already in cart
      : [...cartItems, { id: item.id, cart_quantity: 1 }]; // Add if not in cart

    // Save to local storage
    storage.set("cart_products", JSON.stringify(updatedCartItems));

    // Dispatch action to update the store
    dispatch(Actions.SetProductToCart(updatedCartItems));

    // Show notification
    notification(
      cartItemExists
        ? t("Успешно удалено из корзинки") // Removed message
        : t("Успешно добавлено на корзинку"), // Added message
      {
        type: cartItemExists ? "info" : "success",
      }
    );
  };

  const oldPrice = item.old_price ? Number(item.old_price) : 0;

  return (
    <div className="product-card">
      <Link className="product-card__link" to={`/product/${item.slug}`} />

      {item.is_sale ? (
        <div className="product-card__type --sale">{t("Акция")}</div>
      ) : item.is_new ? (
        <div className="product-card__type">{t("Новинка")}</div>
      ) : null}

      <div className="product-card__img">
        <img src={get(item, "file.thumbnails.low.src")} alt="" />
      </div>

      <div className="product-card__box">
        <div className="product-card__name">
          {get(item, `category.name_${currentLangCode}`)}
        </div>
        <div
          className="product-card__info"
          style={{ marginBottom: oldPrice > 0 ? "40px" : "24px" }}
        >
          {get(item, "translate.title")}
        </div>
        {oldPrice > 0 && (
          <div className="product-card__old-price">
            {helpers.convertToReadable(oldPrice) + " " + t("сум")}
          </div>
        )}
        <div className="product-card__price">
          {item.quantity > 0
            ? helpers.convertToReadable(item.price) + " " + t("сум")
            : t("нет в наличии")}
        </div>
      </div>

      <div
        className={`product-card__add-to-cart ${hasCart ? "--success" : ""}`}
        onClick={addToCart}
      >
        <img
          src={require(`../../../assets/icon/add-to-cart${
            hasCart ? "-success" : ""
          }.svg`)}
          alt=""
        />
      </div>
    </div>
  );
};

export default ProductCard;
